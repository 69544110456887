#home,
#about,
#work,
#skills,
#testimonials,
#contact {
	min-height: 100vh; /* Ensure each section takes up at least full viewport height */
}

html {
	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.app {
	background-color: var(--primary-color);
	font-family: var(--font-base);
}

.app__whitebg {
	background-color: var(--white-color);
}

.app__primarybg {
	background-color: var(--primary-color);
}

.app__container {
	width: 100%;
	min-height: 100vh;

	display: flex;
	flex-direction: row;
}

.app__flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.app__wrapper {
	flex: 1;
	width: 100%;
	flex-direction: column;
	padding: 4rem 2rem;

	@media screen and (max-width: 450px) {
		padding: 4rem 1rem 2rem;
	}
}

.copyright {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;

	position: fixed;
	right: 0;
	bottom: 0;
	margin: 0 2rem 2rem 0;

	p {
		font-size: 0.8rem;
		text-align: left;
		color: var(--gray-color);
		text-transform: uppercase;

		@media screen and (min-width: 2000px) {
			font-size: 1.75rem;
		}
	}
}

.head-text {
	font-size: 2.75rem;
	font-weight: 800;
	text-align: center;
	color: var(--black-color);
	text-transform: capitalize;

	span {
		color: var(--secondary-color);
	}

	@media screen and (min-width: 2000px) {
		font-size: 4rem;
	}

	@media screen and (max-width: 450px) {
		font-size: 2rem;
	}
}

.bold-text {
	font-size: 1rem;
	font-weight: 800;
	color: var(--black-color);
	text-align: left;

	@media screen and (min-width: 2000px) {
		font-size: 2rem;
	}

	@media screen and (max-width: 450px) {
		font-size: 0.9rem;
	}
}

.app__social {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	left: 0;

	padding: 1rem;

	div {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: var(--white-color);
		margin: 0.25rem 0;
		border: 1px solid var(--lightGray-color);

		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		transition: all 0.3s ease-in-out;

		svg {
			width: 15px;
			height: 15px;
			color: var(--gray-color);
		}

		&:hover {
			// background-color: var(--secondary-color);
			// border-color: var(--secondary-color);

			svg {
				color: var(--white-color);
			}
		}

		@media screen and (min-width: 2000px) {
			width: 70px;
			height: 70px;

			margin: 0.5rem 0;

			svg {
				width: 30px;
				height: 30px;
			}
		}
	}
}

.btnT:hover {
	background: #1da1f2;
}

.btnF:hover {
	background: #3b5998;
}

.btnI:hover {
	background: linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
}

.btnGH:hover {
	background: #211f1f;
}

.btnD:hover {
	background: #8fd457;
}

.app__navigation {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	right: 0;

	padding: 1rem;

	.app__navigation-dot {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #cbcbcb;
		margin: 0.5rem;
		cursor: pointer;

		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: var(--secondary-color);
		}

		@media screen and (min-width: 2000px) {
			width: 20px;
			height: 20px;
		}
	}
}

.app__social,
.app__navigation {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
}

@media screen and (max-width: 500px) {
	.app__navigation,
	.app__social,
	.copyright {
		display: none;
	}
}
